<template>
  <div>
    <CRow>
      <CCol sm="12">
        <AccountView />
      </CCol>
    </CRow>
  </div>
</template>

<script>
import AccountView from '../../components/administrator/AccountView.vue'
import ws from '../../services/account';
import store from '../../store'

export default {
  name: 'Account',
  components: { AccountView },
  data: function () {
		return {
        account: false
    }
  },
  mounted: async function() {

  },
  methods: {
    async refreshItems (tab){
      this.loading();
      let response = await ws.getAccounts(tab); 
      
      if(response.type == "success"){
        this.accounts = response.data;
      }

      this.loaded();
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>
