<template>
  <div>
    <CRow>
      <CCol sm="3">
       <CCard class="invoice-card">
        <CCardHeader color="info">
          Información General
          <div class="card-header-actions">
            
          </div>
        </CCardHeader>
        <CCardBody>
          <b>{{account.name}}</b>
          <br />
          {{account.email}}
          <br />
          <br />
          <span v-if="account.user_id > 0">
            <b>Total de Logins: </b>{{account.user.total_logins}}
            <br />
            <b>Último Acceso: </b>{{account.user.last_login | date}} ({{account.user.last_login.substring(11,16)}})
          </span>
          <span v-if="account.user_id == 0"><br /><br /></span>
        </CCardBody>
      </CCard>
      </CCol>
      <CCol sm="6">
       <CCard class="invoice-card">
        <CCardHeader color="info">
          <slot>
            Información Fiscal
          </slot>
        </CCardHeader>
        <CCardBody>
          <b>{{fiscal ? fiscal.rfc : ''}}</b>
          <br />
          {{fiscal.fiscal_name}}
          <br />
          <br />
          <b>Estado: </b>{{fiscal.state_fiscal ? fiscal.state_fiscal.name : ''}} <b>C.P.: </b>{{fiscal.zipcode}}
          <br />
          <b>Regimen: </b>{{fiscal.regimen}} - {{fiscal.regimen_fiscal ? fiscal.regimen_fiscal.name : ''}}
        </CCardBody>
      </CCard>
      </CCol>
      <CCol sm="3">
       <CCard class="invoice-card">
        <CCardHeader color="info">
          <slot>
            Información del Paquete
          </slot>
        </CCardHeader>
        <CCardBody>
          <b>Paquete: </b>{{package.name}}
          <br />
          <b>Precio Normal: </b>{{package.price | currency}}
          <br />
          <br />
          <b>Timbres: </b>{{package.code == 'UNLIMITED' ? '&infin;' : details.stamps}}
          <br />
          <b>Vigencia: </b>{{details.deadline | date}}
        </CCardBody>
      </CCard>
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <slot name="header">
              Historial de Compras
            </slot>
          </CCardHeader>
          <CCardBody>
            <CDataTable
              :hover="false"
              :striped="true"
              :border="true"
              :small="true"
              :fixed="true"
              :items="items"
              :fields="fields"
              :items-per-page="10"
              pagination
            >
              <template #date="{item}"><td class="w150 text-center">{{ item.date | date }}</td></template>
              <template #folio="{item}"><td class="w150 text-center">{{ item.folio }}</td></template>
              <template #stamps="{item}"><td class="w100 text-center">{{ item.stamps }}</td></template>
              <template #deadline="{item}"><td class="w150 text-center">{{ item.deadline | date }}</td></template>
              <template #price="{item}"><td class="w100 text-right"><span style="float: left"><span v-if="item.currency != 'MXN'" style="font-size: 12px;">{{item.currency}}</span> $</span>{{ item.price | currency }}</td></template>
              <template #customer_name="{item}"><td class="text-lefy"><span style="font-weight: bold;">{{item.receptor}}</span><br>{{item.customer_name}}</td></template>
              <template #actions="{item}">
                <td class="table_actions b3">
                  <table style="padding: 0px; margin: auto; ">
                    <tr>
                    <td style="padding: 0px; border: 0px !important;">
                      <CButton color="info" size="sm" @click="viewPayment(item)">Ver Pago</CButton>
                    </td>
                    <td style="padding: 0px; border: 0px !important;">
                      <CDropdown color="info" togglerText="" size="sm">
                        <CDropdownItem href="#" @click="viewPayment(item)">Ver Pago</CDropdownItem>
                        <CDropdownDivider/>
                        <CDropdownItem href="#" v-if="item.cfdi_id > 0"  @click="downloadXML(item.id)">Descargar XML</CDropdownItem>
                        <CDropdownItem href="#" @click="downloadPDF(item.id)">Descargar PDF</CDropdownItem>
                      </CDropdown>
                    </td>
                    </tr>
                  </table>
                </td>
              </template>
            </CDataTable>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardBody class="text-left">
            <CButton  color="dark" size="sm" @click="backAccounts()">Volver a las Cuentas</CButton>
            <div class="card-header-actions">
              <CButton color="success" size="sm" @click="packageModal(account)">Asignar Paquete</CButton>
              <CButton color="info" size="sm" @click="loginAccount(account)">Acceder a Cuenta</CButton>
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <PackageModal ref="packageModal" @storePackage="storePackage"></PackageModal>
  </div>
</template>

<script>
import PackageModal from '../administrator/modals/PackageModal.vue'

import account_ws from '../../services/account';
import general_ws from '../../services/general';
import login from '../../services/login';
import store from '../../store'
import axios from 'axios'

export default {
  name: 'AccountView',
  components: { PackageModal },
  props: {
    
  },
  data() {
    return {
      id: 0,
      account: false,
      fiscal: false,
      details: false,
      package: false,
      toasts: [],
      fields: [
        {key: 'date', label: 'Fecha'},
        {key: 'folio', label: 'Folio'},
        {key: 'name', label: 'Paquete'},
        {key: 'stamps', label: 'Timbres'},
        {key: 'deadline', label: 'Vigencia'},
        {key: 'price', label: 'Precio'},
        //{key: 'actions', label: 'Acciones', class: 'actions' },
      ],
      items: []
    }
  },
  mounted: async function() {
      this.loading();
      this.id = await this.$route.params.id;

      let response = await account_ws.getAccount(this.id);

      if(response.type == "success"){
        this.account = response.data;
        this.fiscal = response.data.fiscal;
        this.details = response.data.detail;
        this.package = response.data.package;
      }

      let response2 = await account_ws.getPurchases(this.id);

      if(response2.type == "success"){
        this.items = response2.data;
      }
  },
  methods: {
    packageModal (data) {
      this.$refs.packageModal.addPackageModal(data);
    },
    async storePackage(form) {
      let data = {
        purchase_account_id: this.account.id,
        package_id: form.package,
        deadline: form.deadline.toISOString().substring(0,10),
        price: form.price,
        stamps: form.stamps,
        invoice: form.invoice,
        payment_type: form.payment_type
      }

      let response = await account_ws.storePurchase(data);

      if(response.type == "success"){
        this.account = response.data;
        this.fiscal = response.data.fiscal;
        this.details = response.data.detail;
        this.package = response.data.package;
      }

      let response2 = await account_ws.getPurchases(this.account.id);

      if(response2.type == "success"){
        this.items = response2.data;
      }

      this.showToast(response.type, response.message);

      this.loaded();
    },
    loginAccount: async function(account){
      let response = await login.loginOwner(account.user.access_token, account.id);

      if(response.type == "success"){
        let data = response.data;

        localStorage.setItem("token", data.access_token);

        axios.defaults.headers.common['access-token'] = data.access_token;

        window.location.href = "/#/dashboard";
        window.location.reload();
      }
      else{
        alert(response.message);
      }
    },
    backAccounts () {
      window.location.href = "/#/accounts";
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  },
  computed: {
    
  }
}
</script>
