<template>
  <CModal
    :title="title"
    :color="color"
    size="lg"
    :show.sync="packageModal"
  >
    <CRow>
      <CCol md="6">
        <CSelect
          label="Paquete:"
          :isValid="checkIfValid('package')"
          :value.sync="$v.form.package.$model"
          :options="packagesOptions"
          @change="changePackage"
        />
      </CCol>
      <CCol md="4">
        <CSelect
          label="Forma de Pago:"
          :isValid="checkIfValid('payment_type')"
          :value.sync="$v.form.payment_type.$model"
          :options="paymentTypeOptions"
        />
      </CCol>
      <CCol md="2">
        <CSelect
          label="Factura:"
          :value.sync="$v.form.invoice.$model"
          :options="invoiceOptions"
        />
      </CCol>
    </CRow>
    <CRow>
      <CCol md="4">
        <CInput
          label="Timbres:"
          :lazy="false"
          :value.sync="$v.form.stamps.$model"
          placeholder=""
          autocomplete="stamps"
          invalidFeedback="Este campo debe ser un valor numérico."
        />
      </CCol>
      <CCol md="4">
        <div role="group" class="form-group">
          <label>Vigencia:</label>
          <v-date-picker
            class="date-picker"
            v-model="$v.form.deadline.$model"
            :isValid="checkIfValid('deadline')"
            locale="es-MX" size="sm"
          />
        </div>
      </CCol>
      <CCol md="4">
        <CInput
          label="Precio:"
          :lazy="false"
          :value.sync="$v.form.price.$model"
          placeholder=""
          autocomplete="price"
          invalidFeedback="Este campo debe ser un valor numérico."
        />
      </CCol>
    </CRow>
      <template #footer>
        <CButton @click="closeModal" color="secondary">Cancelar</CButton>
        <CButton @click="storePackage" :color="color">Aceptar</CButton>
      </template>
  </CModal>
</template>

<script>

import account_ws from '../../../services/account';
import general_ws from '../../../services/general';
import store from '../../../store'
import moment from 'moment';

import { validationMixin } from "vuelidate"
import { required, minLength, maxLength, integer, minValue, email} from "vuelidate/lib/validators"
import { DatePicker } from 'v-calendar'

import moment_timezone from 'moment-timezone'
moment.tz.setDefault('America/Mexico_city')

let today = new moment();
today = new moment(today.format("YYYY-MM-DD HH:m:ss"));

export default { 
  name: 'PackageModal',
  props: {

  },
  components: {
    'v-date-picker': DatePicker
  },
  data () {
    return {
      packageModal: false,
      title: "Asignar Paquete", 
      color: "info",
      isEdit: false,
      form: {},
      submitted: false,

      packages: [],
      packagesOptions: [],
      paymentTypeOptions: [
        {label: "EFECTIVO", value: "CASH"},
        {label: "TRANSFERENCIA", value: "TRANSFER"},
        {label: "CORTESIA GRATUITA", value: "FREE"}
      ],
      invoiceOptions: [
        {label: "NO", value: 0},
        {label: "SÍ", value: 1}
      ],
    }
  },
  mounted: async function() {
    let response = await general_ws.getPackageStamps();

    if(response.type == "success"){
      this.packages = response.data;
      this.packagesOptions = await this.$parseSelectOptionsOrdered(this.packages, "id", "name:type");
    }

    this.loaded();
  },
  methods: {
    async addPackageModal (account) {
      this.packageModal = true;
      this.color = "info";
      this.title = 'Asignar Paquete (Compra)';
      this.account = account;

      let deadline = new moment();

      this.form = {
          package: account.package.id,
          deadline: deadline.add(1, 'years').toDate(),
          payment_type: 'TRANSFER',
          stamps: account.package.quantity,
          price: account.package.price > 0 ? this.$options.filters.decimal(account.package.price) : '',
          invoice: 0
      };

      this.packages.forEach(item => {
        if(this.form.package == item.id){
          this.form.price = this.$options.filters.decimal(item.price.replaceAll(',', ''));
          this.form.stamps = item.quantity;

          if(this.form.stamps == 0){
            this.form.stamps = 'ILIMITADO';
          }
        }
      });
    },
    async changePackage() {
      let deadline = new moment();
      this.packages.forEach(item => {
        if(this.form.package == item.id){
          this.form.price = this.$options.filters.decimal(item.price.replaceAll(',', ''));
          this.form.stamps = item.quantity;
          this.form.package = item.id;

          if(this.form.stamps == 0){
            this.form.stamps = 'ILIMITADO';
          }

          if(item.code == "FREE"){
            this.form.deadline = deadline.add(15, 'days').toDate();
          }
          else{
            this.form.deadline = deadline.add(1, 'years').toDate();
          }
        }
      });
    },
    async storePackage () {
      this.$emit("storePackage", this.form );

      this.closeModal();
    },
    closeModal () {
      this.packageModal = false;
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    },
    checkIfValid (fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      }
      
      return !(field.$invalid)
    }
  },
  computed: {
    formString () { return JSON.stringify(this.form, null, 4) },
    isValid () { return !this.$v.form.$invalid },
    isDirty () { return this.$v.form.$anyDirty },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      package: {
        required
      },
      deadline: {
        required
      },
      payment_type: {},
      stamps: {},
      price:{},
      invoice: {}
    }
  },
}
</script>